body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  text-align: center;
  font-family: Be Vietnam Pro,sans-serif;;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  font-weight: 700;
  font-size: 65px;
  color: #FFC045;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.app-splash {
  background-color: #F1F1F1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(9px + 2vmin);
  color: #57534E;
}

.app-splash header {
  margin-top: 30vh;
}

.app-splash footer {
  margin-bottom: 5vh;
}

.app-splash footer .powered-by {
  font-size: 15px;
  margin-bottom: 5px;
}

.app-splash .content {
  margin: auto;
  font-size: 22px;
}

.app-splash .content > div {
  margin-top: 4px;
}
.app-splash .content .sub {
  font-style: italic;
  margin-top: 10px;
}

.medium {
  font-size: .8em;
}

.small {
  font-size: .4em;
}

a {
  color: #FFC045;
}

a:visited {
  color: #f2af2a;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

